import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, firstValueFrom, throwError} from "rxjs";
import {CompanyInfoResponseDto} from "../dto/company-info-response-dto";
import {ConfigService} from "../services/config/config.service";
import {CompanyHierarchyResponseDto} from "../dto/company-hierarchy-response-dto";
import {CompaniesHierarchiesResponseDto} from "../dto/companies-hierarchies-response-dto";
import {Account} from "../models/users-list-item";
import {UserPerCompanyItem} from "../dto/users/user-per-company-item";
import {AccountCreationResponseDto} from "../dto/users/UserAccountsCreateResponseDto";
import {PagedResponse} from "@carool1/ngx-carool-ds";

@Injectable({
  providedIn: 'root'
})
export class ReferenceApiService {

  private http = inject(HttpClient);
  private configService = inject(ConfigService);

  private config = this.configService.applicationProperties;

  getCompany(company: string) {
    return firstValueFrom(this.http.get<CompanyInfoResponseDto>(`${this.config.api.referenceApi}/companies/${company}`).pipe(
      catchError((err) => {
        if (err.status === 404 && company.includes('-')) {
          const fallbackCompany = company.split('-')[0];
          return this.http.get<CompanyInfoResponseDto>(`${this.config.api.referenceApi}/companies/${fallbackCompany}`)
        }
        return throwError(() => err);
      })
    ));
  }

  getCompanyHierarchy(id: number) {
    return this.http.get<CompanyHierarchyResponseDto>(
      `${this.config.api.referenceApi}/companies/${id}/hierarchies`
    );
  }

  getCompanyHierarchies() {
    return this.http.get<CompaniesHierarchiesResponseDto>(
      `${this.config.api.referenceApi}/companies-hierarchies`
    );
  }

  getReport(analysisId: number) {
    return this.http.get<{ url: string }>(`${this.config.api.referenceApi}/v3/analyses/${analysisId}/report`);
  }

  getUsers(companyKey: string, page: number, size: number) {
    return firstValueFrom(this.http.get<PagedResponse<UserPerCompanyItem>>(`${this.config.api.referenceApi}/v2/users`, {
      params: new HttpParams({
        fromObject: {
          companyKey,
          page,
          size
        }
      })
    }));
  }

  createUserAccount(accounts: Account[]) {
    return this.http.post<AccountCreationResponseDto>(`${this.config.api.referenceApi}/v2/setup/accounts`, {accounts});
  }
}
